<script>
    import { onMount, onDestroy } from "svelte";
    import { setIsOnline, setIsOffline, onlineStatus } from "../../stores/onlineStatus/store";
    import { toast } from "svelte-sonner";

    onMount(() => {
        // on- and offline statuses
        if (!$onlineStatus) toast.error("Offline");
        window.addEventListener("online", setIsOnline);
        window.addEventListener("offline", setIsOffline);

        // beforeunload: this works only partially! sync to cloud works, but by the time we get the response,
        // the user's browser is already closed. The entry in indexedDB doesn't get the "isSynced" flag set to true.
        // I believe we shouldn't add this, as it could result in bugs. (for example employee A is done, all the data is
        // synced. Employee B does a review and changes stuff. Employee A comes back online and the data is synced again,
        // overwriting the changes made by employee B)
        // window.addEventListener("beforeunload", async () => {
        //     await syncLocalToCloud();
        // });
    });

    onDestroy(() => {
        // on- and offline statuses
        window.removeEventListener("online", setIsOnline);
        window.removeEventListener("offline", setIsOffline);
    });
</script>
